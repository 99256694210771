body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  
body, 
.MuiTypography-root, 
.MuiButton-root, 
.MuiButtonBase-root, 
.MuiInputBase-root, 
.MuiMenuItem-root,
.MuiFormLabel-root,
.MuiInputLabel-root,
.MuiListItemText-primary,
.MuiChip-label,
.MuiTab-root,
.MuiAccordionSummary-content,
.MuiAccordionDetails-root,
.MuiAlert-message,
.MuiSnackbarContent-message,
.MuiDataGrid-columnHeaderTitle,
.MuiDataGrid-cellContent,
.MuiTableCell-root,
.MuiTableHead-root,
.MuiTableRow-root,
.MuiTableContainer-root,
.MuiTablePagination-root,
.MuiTableSortLabel-root,
.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel,
.MuiTablePagination-select,
.MuiTablePagination-selectIcon,
.MuiTablePagination-actions,
.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel,
.MuiTablePagination-select,
.MuiTablePagination-selectIcon,
.MuiTablePagination-actions,
.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel
{
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

* {
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* add background color to the body */

/* TODO : 추후 내용이 많아지면 레이아웃이나 컴포넌트 별로 파일 분리 필요 */
/* DataGrid 스타일 추가 */
.MuiDataGrid-cell {
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
}

.MuiDataGrid-columnHeaders {
  background-color: rgba(245, 245, 245, 1) !important;
}

.MuiDataGrid-columnHeader {
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
}

/* actions 컬럼 헤더 스타일링 */
.MuiDataGrid-columnHeader[data-field="actions"] {
  background-color: #f5f5f5;
}

/* .MuiDataGrid-columnHeader:last-child {
  border-right: none !important;
} */

.MuiDataGrid-cell:last-child {
  border-right: none !important;
}