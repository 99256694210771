.react-multi-email {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.4em 0.5em;
  background: #fff;
  /* border: 1px solid rgba(34, 36, 38, 0.15); */
  border: 1px solid rgba(196, 196, 196, 1);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}
.react-multi-email > span[data-placeholder] {
  display: none;
  position: absolute;
  /* left: 0.5em; */
  left: 0.4em;
  /* top: 0.4em; */
  top: 0.3em;
  padding: 0.4em;
  line-height: 1.21428571em;
}
.react-multi-email.focused {
  /* border-color: #85b7d9; */
  border: 2px solid #1976D2;
  background: #fff;
}

.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  /* color: #ccc; */
  color: #777777;
}
.react-multi-email.focused > span[data-placeholder] {
  display: none;
}

.react-multi-email > input {
  flex: 1;
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
  font-size: 1rem; /* add */
}

.react-multi-email [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  /* background-color: #f3f3f3; */
  background-color: #ffffff;
  background-image: none;
  padding: 0.4em 0.8em;
  /* color: rgba(0, 0, 0, 0.6); */
  color: #1976d2;
  text-transform: none;
  /* font-weight: 600; */
  font-weight: 400;
  /* border: 0 solid transparent; */
  border: 1px solid #1976d2;
  /* border-radius: 0.28571429rem; */
  border-radius: 1rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
  margin-left: 0;
}
.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}

#input_files_label:hover {
  .input-label {
    background-color: #1565c0;
    border-radius: 4px;
    /* padding: 8px; */
    /* width: 86px;
    height: 31px; */
  }
}